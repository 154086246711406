/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../../LCFQheader";
import SbcgRadio from "../../../common/sbgl/SbcgRadio";
import { RxUtil } from "@/assets/util.js";
import { format } from "@/assets/app.js";
export default {
  name: "lcfqBywx",
  components: {
    LCFQheader,
    SbcgRadio
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "设备采购申请单",
      showListpage: false,
      //userSelect
      single: false,
      allowSelect: true,
      showUserDialog: false,
      //部门
      showGroupDialog: false,
      allowSelect: true,
      nodeGroupVm: {},
      showData: false,
      minDate: new Date(),
      userID: "",
      solId: "",
      boDefId: "",
      formKey: "",
      resultFa: [],
      wpInfoData: {},
      gysid: "",
      data: {
        sb_sqr: "",
        sb_sqbm: ""
      }
    };
  },
  created() {
    this.cgORxj();
    // this.initBySolInstId();
  },

  methods: {
    initBySolInstId() {
      // let solId = this.$route.params.solId;
      this.solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + this.solId;
      this.$ajax.post(url).then(response => {
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
        let sqrId = response.data.formModels[0].jsonData.fgld;
        let sqrName = response.data.formModels[0].jsonData.fgld_name;
        let sqrObj = [{
          id: sqrId,
          text: sqrName
        }];
        this.data.sb_sqr = JSON.stringify(sqrObj);
        let sqbmId = response.data.formModels[0].jsonData.sb_sqbm;
        let sqbmName = response.data.formModels[0].jsonData.sb_sqbm_name;
        let sqbm_name = [{
          id: sqbmId,
          text: sqbmName
        }];
        this.data.sb_sqbm = JSON.stringify(sqbm_name);
      });
    },
    async cgORxj() {
      this.solId = this.$route.params.solId;
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + this.solId + "&instId=" + instId;
        this.$ajax.post(url).then(response => {
          this.data = response.data.formModels[0].jsonData;
          this.actDefId = response.data.bpmSolution.actDefId;
          this.boDefId = response.data.formModels[0].boDefId;
          this.formKey = response.data.formModels[0].formKey;
          let sqrId = response.data.formModels[0].jsonData.fgld;
          let sqrName = response.data.formModels[0].jsonData.fgld_name;
          let sqrObj = [{
            id: sqrId,
            text: sqrName
          }];
          this.data.sb_sqr = JSON.stringify(sqrObj);
          let sqbmId = response.data.formModels[0].jsonData.sb_sqbm;
          let sqbmName = response.data.formModels[0].jsonData.sb_sqbm_name;
          let sqbm_name = [{
            id: sqbmId,
            text: sqbmName
          }];
          this.data.sb_sqbm = JSON.stringify(sqbm_name);
        });
      } else {
        this.initBySolInstId();
      }
    },
    //选代理人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    //科室选择
    selectGroupDialog(vm) {
      this.showGroupDialog = true;
      this.$refs.sysGroup.type = "group";
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    //时间
    onConfirmFw(timestamp) {
      this.data.sb_yjsysj = format(timestamp);
      this.showData = false;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        var test = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    showList() {
      this.showListpage = true;
    },
    tdObj(item) {
      this.data.sb_tjcj = item.F_FWGYSW;
      this.gysid = item.ID_;
      this.showListpage = false;
    },
    datas() {
      let sb_sqr = JSON.parse(this.data.sb_sqr);
      let sb_sqbm = JSON.parse(this.data.sb_sqbm);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            sb_sqr: sb_sqr[0].id,
            sb_sqr_name: sb_sqr[0].text,
            sb_sqmc: this.data.sb_sqmc,
            sb_sqbm: sb_sqbm[0].id,
            sb_sqbm_name: sb_sqbm[0].text,
            sb_sbmc: this.data.sb_sbmc,
            sb_ggxh: this.data.sb_ggxh,
            sb_cgsl: this.data.sb_cgsl,
            sb_yjsysj: this.data.sb_yjsysj,
            sb_tjcj: this.data.sb_tjcj,
            sb_tjcj_name: this.data.sb_tjcj,
            sb_ygjg: this.data.sb_ygjg,
            gysid: this.gysid,
            sb_lc: this.data.sb_lc,
            sb_bqdd: this.data.sb_bqdd,
            sb_zqd: this.data.sb_zqd,
            sb_jd: this.data.sb_jd,
            sb_ytjqt: this.data.sb_ytjqt
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    onSubmit() {
      // if(this.data.je != ""){
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
      // }else{
      //   this.errorToast("金额不能为空！");
      // }
    },

    onPreservation() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};