/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
// import { getTestTable } from "../../api/index";

import { unique } from "@/assets/app.js";
import SbcgInfo from "./SbcgInfo";
export default {
  components: {
    SbcgInfo
  },
  data() {
    return {
      searchVal: "",
      showInfo: false,
      data: [],
      mount: [],
      mountDupRe: [],
      pageNum: 1,
      prePage: 1,
      maxPageNum: 1,
      totalNum: 0,
      // pageIndex: 0,
      pageSize: 10,
      result: {},
      itemString: "",
      gysInfoData: {}
    };
  },
  created() {
    this.getCgwpInfo();
    this.data = this.mount;
    // this.onEnter();
  },

  mounted() {},
  methods: {
    getCgwpInfo() {
      this.mount = [];
      var pageNum = this.pageNum - 1;
      var formdata = new FormData();
      formdata.append("pageIndex", pageNum);
      formdata.append("pageSize", this.pageSize);
      if (this.searchVal != "") {
        formdata.append("Q_F_FWGYSW_S_LK", this.searchVal);
      }
      var url = _baseUrl + `/equipmentMgmt/equipment/eQUIPMENT/listData.do`;
      this.$ajax.post(url, formdata).then(res => {
        this.data = res.data.data;
        this.data.map(item => {
          if (JSON.stringify(this.mount).indexOf(item.ID_) == -1) {
            this.mount.push(item);
          }
        });
        this.totalNum = res.data.total;
        this.maxPageNum = Math.ceil(this.totalNum / 10);
        console.log(this.mount.length);
      });
    },
    onPlus() {
      this.pageNum += 1;
      this.getCgwpInfo();
    },
    onMinus() {
      this.pageNum -= 1;
      this.getCgwpInfo();
    },
    onEnter() {
      document.onkeydown = e => {
        if (e.keyCode === 13) {
          var pageNum = this.pageNum;
          var maxPageNum = this.maxPageNum;
          if (pageNum <= maxPageNum) {
            // this.prePage = pageNum;
            this.getCgwpInfo();
          } else {
            Toast("请正确输入页数！");
          }
        }
      };
    },
    //搜索
    onSearch() {
      this.pageNum = 1;
      this.getCgwpInfo();
    },
    //消除搜索
    onClosex() {
      this.pageNum = 1;
      this.getCgwpInfo();
    },
    //详情
    onInfo(item) {
      console.log(item);
      this.showInfo = true;
      this.gysInfoData = item;
    },
    //确定
    onSelects() {
      // console.log(this.result)
      this.$emit("tdObj", this.result);
    }
  }
};